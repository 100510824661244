import { Button } from '@/components/shared/buttons/button';
import { ButtonGroup } from '@/components/shared/buttons/button-group';
import { Text } from '@/components/shared/element/text';
import { youtubeURL } from '@/components/shared/utility/format';
import { styled } from '@/stitches.config';
import { IItem, IMy24Theme, IMy24Tracking } from '@/types/shared';

const StyledContent = styled('div', {
  width: '100%',
  fontSize: 0,
  variants: {
    align: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
    },
  },
  defaultVariants: {
    align: 'center',
  },
});

interface IProps extends React.ComponentProps<typeof StyledContent> {
  css?: any;
  theme?: IMy24Theme;
  className?: string;
  headline: IItem;
  dataTest?: string;
  dataTrack?: IMy24Tracking;
  onClick?: () => void;
}

export const ContentWithAction: React.FC<IProps> = ({
  theme = 'dark',
  ...props
}) => {
  const options = {
    align: props.align,
  };
  return (
    <StyledContent css={props?.css} className={props?.className} {...options}>
      {props?.headline?.preTitle && (
        <Text
          as="h2"
          size="text-h3"
          variant={theme === 'light' ? 'text-gray-darkest' : 'text-white'}
          wrap
          dangerouslySetInnerHTML={{
            __html: props?.headline?.preTitle,
          }}
          data-test={props?.dataTest + '_preTitle'}
          css={{ marginBottom: '$space-1' }}
        />
      )}
      {props?.headline?.title && (
        <Text
          as="h3"
          size="text-h2"
          variant={theme === 'light' ? 'text-gray-darkest' : 'text-white'}
          wrap
          dangerouslySetInnerHTML={{
            __html: props?.headline?.title,
          }}
          data-test={props?.dataTest + '_title'}
        />
      )}
      {props?.headline?.youtube && (
        <Button
          label={props?.headline?.youtube?.label}
          icon={props?.headline?.youtube?.icon}
          isTransparent
          isIconLarge
          size={props?.headline?.youtube?.size}
          onClick={props?.onClick}
          tracking={{
            dataTrack: props?.dataTrack,
            dataTrackSection: props?.dataTest,
            dataTrackText: props?.headline?.youtube?.label,
            dataTrackValue: props?.headline?.preTitle,
            dataTrackUrl: youtubeURL(props?.headline?.youtube?.url),
          }}
          test={{
            dataTest: props?.dataTest + `_video_button`,
          }}
          css={{ marginTop: '$space-5' }}
        />
      )}
      {props?.headline?.buttons?.length > 0 && (
        <ButtonGroup
          className="content-buttons"
          wrap="noWrap"
          justify="center"
          css={{ pe: 'all', marginTop: '$space-6' }}
        >
          {props?.headline?.buttons?.map((button, i) => (
            <Button
              key={i}
              href={button?.url}
              target={button?.target}
              border={button?.border}
              label={button?.label}
              icon={button?.icon}
              variant={button?.color}
              size={button?.size}
              isMinWidth
              tracking={{
                dataTrack: props?.dataTrack,
                dataTrackSection: props?.dataTest,
                dataTrackText: button?.label,
                dataTrackUrl: button?.url,
                dataTrackValue: 'cta-button',
              }}
              test={{
                dataTest:
                  button?.url === undefined
                    ? `${props?.dataTest}_home_button`
                    : `${props?.dataTest}_cta_button`,
              }}
              onClick={button?.url === undefined && props?.onClick}
            />
          ))}
        </ButtonGroup>
      )}
    </StyledContent>
  );
};

ContentWithAction.displayName = 'ContentWithAction';
