import React from 'react';

import { IconUero5Max } from '@/components/layouts/header/icon-uero-5-max';
import { Badge } from '@/components/layouts/header/submenu-model-link';
import { Button } from '@/components/shared/buttons/button';
import { Icon } from '@/components/shared/element/icons';
import EngineIcon from '@/components/shared/element/icons/global/engine';
import RearIcon from '@/components/shared/element/icons/global/rear';
import WeightIcon from '@/components/shared/element/icons/global/weight';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Flex } from '@/components/shared/layout/flex';
import { Grid } from '@/components/shared/layout/grid';
import { Image } from '@/components/shared/media/image';
import { useWindowSize } from '@/components/shared/utility/hooks';
import {
  CARD_CANCEL_BUTTON_LABEL,
  CARD_COMPARE_BUTTON_LABEL,
  CARD_DETAIL_BUTTON_LABEL,
  CARD_ENGINE_LABEL,
  CARD_PRICE_LABEL,
  CARD_REAR_LABEL,
  CARD_WEIGHT_LABEL,
} from '@/contents/cv/product';
import { styled } from '@/stitches.config';

const BadgeWrap = styled(Box, {
  '> *': {
    maxWidth: '$size-20',
  },
});

const ImageWrap = styled(Box, {
  maxWidth: 280,
});

const ProductListContentItem = styled(Box, {
  transition: 'box-shadow var(--transition-duration) var(--transition-easing)',
  '&.is-active': {
    bs: 'inset 0px 0px 0px 1px $primary',
  },
});

export type EngineType = 'euro-3' | 'euro-5';
export interface ISelect {
  uid?: string;
  label?: string;
  name?: string;
}
export interface ProductItemCardProps {
  uid?: string;
  engineType?: EngineType;
  subCategoryId?: {
    uid?: string;
    title?: string;
  }[];
  businessTypeId?: {
    uid?: string;
  }[];
  segmentTypeId?: {
    uid?: string;
  }[];
  bodyTypeId?: {
    uid?: string;
  }[];
  title?: string;
  startPrice?: string | number;
  ps?: string | number;
  payload?: string | number;
  ceRange?: string | number;
  ce?: string | number;
  img?: {
    url?: string;
  };
  productButton?: {
    label?: string;
    url?: string;
  };
  itemIndex?: number;
}
interface ProductItemProps extends ProductItemCardProps {
  businessTypeList: ISelect[];
  segmentTypeList: ISelect[];
  bodyTypeList: ISelect[];
  selectedCompare: { selections: string[] };
  handleCompareChange: (key: string) => void;
  disabledCompareButtonItem: boolean;
}

const View: React.FC<ProductItemProps> = ({
  uid,
  title,
  startPrice,
  businessTypeId,
  segmentTypeId,
  bodyTypeId,
  subCategoryId,
  img,
  ps,
  payload,
  ceRange,
  ce,
  disabledCompareButtonItem,
  engineType,
  productButton,
  businessTypeList,
  segmentTypeList,
  bodyTypeList,
  selectedCompare,
  handleCompareChange,
  itemIndex,
}) => {
  const windowSize = useWindowSize();

  const getBusinessTypeData = (data) => {
    const businessTypeData = [];
    businessTypeList.filter((item) => {
      data.forEach((id) => {
        if (id === item.uid) {
          businessTypeData.push(item.label);
        }
      });
    });
    return businessTypeData;
  };

  const getSegmentTypeData = (data) => {
    const segmentTypeData = [];
    segmentTypeList.filter((item) => {
      data.forEach((id) => {
        if (id === item.uid) {
          segmentTypeData.push(item.label);
        }
      });
    });
    return segmentTypeData;
  };

  const getBodyTypeData = (data) => {
    const bodyTypeData = [];
    bodyTypeList.filter((item) => {
      data.forEach((id) => {
        if (id === item.uid) {
          bodyTypeData.push(item.label);
        }
      });
    });
    return bodyTypeData;
  };

  return (
    <ProductListContentItem
      position="relative"
      backgroundColor="white"
      rounded="2"
      padding="4"
      className={
        windowSize.width && selectedCompare.selections.includes(uid)
          ? 'is-active'
          : ''
      }
      test={{
        dataTest: 'product_list_card',
        dataTestBusiness: getBusinessTypeData(
          businessTypeId.map((sub) => sub.uid)
        ),
        dataTestSegment: getSegmentTypeData(
          segmentTypeId.map((sub) => sub.uid)
        ),
        dataTestBody: getBodyTypeData(bodyTypeId.map((sub) => sub.uid)),
      }}
    >
      <Flex direction="column" css={{ '@md': { height: '100%' } }}>
        <Grid css={{ marginBottom: 'auto' }}>
          <Flex alignItems="baseline" justifyContent="between" columnGap="2">
            {title && (
              <Text
                as="h3"
                size="text-body-1"
                font="bold"
                variant={
                  windowSize.width && selectedCompare.selections.includes(uid)
                    ? 'text-red'
                    : 'text-black'
                }
                data-test="title_model"
              >
                {title}
              </Text>
            )}
            {startPrice && typeof startPrice === 'string' && (
              <Text
                as="p"
                size="text-caption"
                css={{ whiteSpace: 'nowrap' }}
                data-test="model_startedprice"
              >
                {CARD_PRICE_LABEL}
                {parseInt(startPrice).toLocaleString()}
              </Text>
            )}
          </Flex>
          {subCategoryId && subCategoryId.length > 0 && (
            <>
              {subCategoryId.map((sub, i) => (
                <Text
                  as="p"
                  size="text-caption"
                  key={i}
                  data-test="truck_category"
                >
                  {sub.title}
                </Text>
              ))}
            </>
          )}
        </Grid>
        <Grid rowGap="2">
          {img && img.url && (
            <ImageWrap
              position="relative"
              display="block"
              width="full"
              marginX="auto"
            >
              <Image
                priority={itemIndex <= 2}
                rootMargin="20% 0px 0px 0px"
                src={img.url}
                alt={title}
                width={600}
                height={450}
                layout="responsive"
                aspectRatio="4by3"
                sources={[
                  {
                    srcset: img.url,
                    options: {
                      width: 320,
                      crop: '4:3',
                    },
                  },
                ]}
              />
              {engineType == 'euro-5' && (
                <BadgeWrap position="absolute" left="0" bottom="1" width="full">
                  <Badge>
                    <IconUero5Max />
                  </Badge>
                </BadgeWrap>
              )}
            </ImageWrap>
          )}
          {(ps || payload || ceRange || ce) && (
            <Grid
              columnGap="2"
              css={{
                gridTemplateColumns: '20px repeat(2, 1fr)',
                gridTemplateRows: 'repeat(3, minmax(24px, auto))',
                px: '$space-2',
              }}
            >
              <Icon size="sm">
                <EngineIcon />
              </Icon>
              <Text as="p" size="text-caption" variant="text-gray">
                {CARD_ENGINE_LABEL}
              </Text>
              <Text
                as="p"
                size="text-caption"
                variant="text-gray"
                font="bold"
                data-test="engine"
              >
                {ps ? ps : '-'}
              </Text>
              <Icon size="sm">
                <WeightIcon />
              </Icon>
              <Text as="p" size="text-caption" variant="text-gray">
                {CARD_WEIGHT_LABEL}
              </Text>
              <Text
                as="p"
                size="text-caption"
                variant="text-gray"
                font="bold"
                data-test="payload"
              >
                {payload ? payload : '-'}
              </Text>
              <Icon size="sm">
                <RearIcon />
              </Icon>
              <Text as="p" size="text-caption" variant="text-gray">
                {CARD_REAR_LABEL}
              </Text>
              <Text
                as="p"
                size="text-caption"
                variant="text-gray"
                font="bold"
                data-test="ce"
              >
                {ceRange
                  ? ceRange
                  : ce &&
                    ce !== '‐' &&
                    ce !== '‑' &&
                    ce !== '‒' &&
                    ce !== '–' &&
                    ce !== '—' &&
                    ce !== '―' &&
                    ce !== '-'
                  ? ce + ' มม.'
                  : '-'}
              </Text>
            </Grid>
          )}
          <Grid templateColumns="2" columnGap="3">
            <Button
              border={
                windowSize.width && selectedCompare.selections.includes(uid)
                  ? false
                  : true
              }
              variant={
                windowSize.width && selectedCompare.selections.includes(uid)
                  ? 'button-gray'
                  : undefined
              }
              onClick={() => handleCompareChange(uid)}
              disabled={
                !selectedCompare.selections.includes(uid) &&
                disabledCompareButtonItem
                  ? true
                  : false
              }
              tracking={{
                dataTrack: 'product-list-card-compare',
                dataTrackText: CARD_COMPARE_BUTTON_LABEL,
                dataTrackValue: title,
              }}
              test={{
                dataTest: 'button_compare',
              }}
              size="sm"
              label={
                windowSize.width && selectedCompare.selections.includes(uid)
                  ? CARD_CANCEL_BUTTON_LABEL
                  : CARD_COMPARE_BUTTON_LABEL
              }
            />
            <Button
              size="sm"
              border
              label={CARD_DETAIL_BUTTON_LABEL}
              href={productButton.url}
              tracking={{
                dataTrack: 'product-list-card-cta',
                dataTrackText: CARD_DETAIL_BUTTON_LABEL,
                dataTrackUrl: productButton.url,
                dataTrackValue: title,
              }}
              test={{
                dataTest: 'button_modelDetail',
              }}
            />
          </Grid>
        </Grid>
      </Flex>
    </ProductListContentItem>
  );
};

View.displayName = 'ProductItem';
export default View;
